<template>
  <v-container fluid>
    <v-data-table
      :items="disciplines"
      :headers="[
        { text: 'Gerät', value: 'name', sortable: false },
        { text: 'Reihenfolge', value: 'order', sortable: false, align: 'center' },
        { text: 'Hinzufügen', value: 'add', sortable: false, align: 'center' },
        { text: 'Entfernen', value: 'del', sortable: false, align: 'center' }
      ]"
      :items-per-page="-1"
      :mobile-breakpoint="0"
      hide-default-footer
    >
      <template #item.order="{item}">
        <base-edit-dialog
          :value="item.order"
          :id="item._id"
          label="Reihenfolge"
          @input="({ value, id: iid }) => updateorder(iid, value)"
        />
      </template>
      <template #item.add="{item}">
        <v-btn
          v-if="!item.active"
          fab
          x-small
          @click="add(item._id)"
        >
          <v-icon>far fa-plus</v-icon>
        </v-btn>
      </template>
      <template #item.del="{item}">
        <v-btn
          v-if="item.active && !item.used"
          fab
          x-small
          @click="del(item._id)"
        >
          <v-icon>far fa-minus</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <h3>Hinweise:</h3>
    <ul>
      <li>Geräte, an denen schon mindestens 1 Turner aufgestellt wurde, können nicht mehr entfernt werden.</li>
      <li>Um ein Gerät löschen zu können, müssen zunächst alle Turner von diesem Gerät abgemeldet werden.</li>
      <li>Die Reihenfolge kann durch einen Klick auf die Zahl angepasst werden.</li>
    </ul>
  </v-container>
</template>

<script>
import { useGraphQL } from '@/plugins/graphql'
import gql from 'graphql-tag'

export default {
  name: 'disciplines',

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    df: {
      type: Array,
      required: true
    }
  },

  computed: {
    disciplines () {
      return (this.df?.map(d => ({
        ...d,
        __typename: undefined,
        active: !!this.e?.disciplines?.find(ed => ed._discipline === d._id),
        order: this.e?.disciplines?.find(ed => ed._discipline === d._id)?.order,
        used: this.e?.teams?.reduce((acc, curr) => {
          return acc || !!curr.athletes?.find(a => !!a.results?.find(r => r._discipline === d._id))
        }, false)
      })) || []).sort((a, b) => {
        if (a.active && b.active) return a.order < b.order ? -1 : 1
        if (a.active) return -1
        if (b.active) return 1
      })
    },
    maxactive () {
      return this.disciplines.filter(d => d.active).reduce((acc, curr) => Math.max(acc, curr.order), 0)
    }
  },

  methods: {
    add (id) {
      this.mutate({
        mutation: gql`mutation($id: UUID!, $discipline: UUID!, $order: Int!) {
          StbM2021WkDisciplineAdd(id: $id, discipline: $discipline, order: $order) { _id }
        }`,
        variables: {
          id: this.id,
          discipline: id,
          order: this.maxactive + 1
        }
      })
    },
    del (id) {
      this.mutate({
        mutation: gql`mutation($id: UUID!, $discipline: UUID!) {
          StbM2021WkDisciplineDelete(id: $id, discipline: $discipline) { _id }
        }`,
        variables: {
          id: this.id,
          discipline: id,
          order: this.active + 1
        }
      })
    },
    updateorder (id, value) {
      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $discipline: UUID!, $order: Int!) {
            StbM2021WkDisciplineUpdateOrder(id: $id, discipline: $discipline, order: $order) { _id }
          }
        `,
        variables: {
          id: this.id,
          discipline: id,
          order: parseInt(value)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
